@import './Styles/ant.scss';
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  // font-family: "Cairo" , sans-serif;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  * {
    font-family: 'Poppins', sans-serif;
  }
  &:lang(ar) {
    font-family: 'Almarai', sans-serif;
    * {
      font-family: 'Almarai', sans-serif !important;
    }
  }
}

#chart {
  direction: ltr;
}
.dark-select {
  .ant-select-selector {
    border-color: #fff !important;
  }
}
.d-none {
  display: none !important;
}
.jvectormap-container {
  // height: 400px;
  // svg{
  //   width: 100%;
  //   height: 400px;
  // }
}
.apexcharts-legend-text {
  text-transform: capitalize;
}
.responsive-chart {
  overflow-x: auto;
  overflow-y: hidden;
  #chart {
    min-width: 800px !important;
  }
}
.responsive-small {
  overflow-x: auto;
  overflow-y: hidden;
  #chart {
    min-width: 450px !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ant-card {
  overflow: hidden;
}

.ant-modal-close {
  top: auto !important;
  // margin-bottom: 8px;
}
// .btn-add-admin{
//   background:#1677FF;
//   border: none;
//   color: white;
//   box-shadow: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 17px;
//   transition: 0.3s;

//   &:hover{
//     background: #1677ffd7 !important;
//   }
// }

.PhoneInput {
  width: 100%;
  height: 40px;
  padding: 10px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  direction: ltr;
  input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding-inline-start: 0;

    &:focus-visible {
      outline: 0;
    }
  }
  .PhoneInputCountry {
    gap: 8px;
  }
}
.ant-picker-input > input[disabled],
.ant-select-selector,
.ant-select-disabled,
.ant-input-outlined[disabled] {
  color: #000 !important;
}
.upload-image-instructor {
  .ant-upload-list-item-container {
    width: auto !important;
    height: auto !important;
    margin: 0 auto;
  }
  .ant-upload-list-item,
  .ant-upload {
    width: 300px !important;
    overflow: hidden;
    height: 250px !important;
    background: #fff;
    margin: 0 auto;
  }
}
.card-blog-list {
  .ant-card-body {
    padding: 0 !important;
  }
}

.toolbar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
  padding: 6px;
  .toolbar-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .button {
      background-color: transparent;
      border: 1px solid #ccc;
      padding: 7px;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #007bff;
        border-color: #007bff;
        color: #fff;
      }
    }

    .button-active {
      background-color: #007bff;
      color: white;
      border: 1px solid #007bff;
      padding: 7px;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // .submit-button {
    //   background-color: #007bff;
    //   color: white;
    //   padding: 10px 20px;
    //   border: none;
    //   border-radius: 4px;
    //   cursor: pointer;
    //   &:hover {
    //     background-color: #0056b3;
    //   }
    // }
  }
}

.tiptap-container {
  width: 100%;
  // padding: 10px;
}

.editor-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
  justify-content: start;
  border: 1px solid #ccc;
  border-top: 0;
  color: #555;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 0 0 10px 10px;
  outline: none;
  min-height: 200px;
}

.content-text-table {
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: start;
  p,
  h1,
  h2,
  h3,
  strong,
  em {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #000000e0;
    line-height: 1.5;
  }
}
